export function About() {
    return (
        <div className="About">
          <h2>Our Services</h2>
          <p>
            The team at <b>Canucks & Crannies</b> aims to help our guests' expand their travel horizons.</p>
          <p>
            You've probably heard of Victoria Island, Banff, and Niagara Falls; 
            <br />but what about The Okanagan, Alberta's Badlands, or the Athabasca Sand Dunes?
          </p>
          <p>
            Our guides bring local knowledge to your adventure, so you can experience Canada's most popular attractions 
            <br />and explore a few nooks and crannies along the way!
          </p>
        </div>
    );
}