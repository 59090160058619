export function Explore() {
    return (
        <div>
            <div id="AB" className="Card">
                <h3>Explore Alberta</h3>
                <ul>
                    <li>Some Place</li>
                    <li>Some Place Else</li>
                </ul>
            </div>
            <div id="BC" className="Card">
                <h3>Explore British Columbia</h3>
                <ul>
                    <li>Some Place</li>
                    <li>Some Place Else</li>
                </ul>
            </div>
            <div id="MB" className="Card">
                <h3>Explore Manitoba</h3>
                <ul>
                    <li>Some Place</li>
                    <li>Some Place Else</li>
                </ul>
            </div>
            <div id="NB" className="Card">
                <h3>Explore New Brunswick</h3>
                <ul>
                    <li>Some Place</li>
                    <li>Some Place Else</li>
                </ul>
            </div>
            <div id="NL" className="Card">
                <h3>Explore Newfoundland & Labrador</h3>
                <ul>
                    <li>Some Place</li>
                    <li>Some Place Else</li>
                </ul>
            </div>
            <div id="NS" className="Card">
                <h3>Explore Nova Scotia</h3>
                <ul>
                    <li>Some Place</li>
                    <li>Some Place Else</li>
                </ul>
            </div>
            <div id="ON" className="Card">
                <h3>Explore Ontatio</h3>
                <ul>
                    <li>Some Place</li>
                    <li>Some Place Else</li>
                </ul>
            </div>
            <div id="PEI" className="Card">
                <h3>Explore Prince Edward Island</h3>
                <ul>
                    <li>Some Place</li>
                    <li>Some Place Else</li>
                </ul>
            </div>
            <div id="QC" className="Card">
                <h3>Explore Quebec</h3>
                <ul>
                    <li>Some Place</li>
                    <li>Some Place Else</li>
                </ul>
            </div>
            <div id="SK" className="Card">
                <h3>Explore Saskatchewan</h3>
                <ul>
                    <li>Some Place</li>
                    <li>Some Place Else</li>
                </ul>
            </div>
            <div id="NT" className="Card">
                <h3>Explore the Northwest Territories</h3>
                <ul>
                    <li>Some Place</li>
                    <li>Some Place Else</li>
                </ul>
            </div>
            <div id="NU" className="Card">
                <h3>Explore Nunavut</h3>
                <ul>
                    <li>Some Place</li>
                    <li>Some Place Else</li>
                </ul>
            </div>
            <div id="YT" className="Card-last">
                <h3>Explore the Yukon</h3>
                <ul>
                    <li>Some Place</li>
                    <li>Some Place Else</li>
                </ul>
            </div>
        </div>
    );
}