export function NavBar() {
    return (
        <nav role="navigation" className="App-navbar">
            <ul role="menubar">
                <li role="menuitem">
                    <a href="#AB">AB</a>
                </li>
                <li role="menuitem">
                    <a href="#BC">BC</a>
                </li>
                <li role="menuitem">
                    <a href="#MB">MB</a>
                </li>
                <li role="menuitem">
                    <a href="#NB">NB</a>
                </li>
                <li role="menuitem">
                    <a href="#NL">NL</a>
                </li>
                <li role="menuitem">
                    <a href="#NS">NS</a>
                </li>
                <li role="menuitem">
                    <a href="#ON">ON</a>
                </li>
                <li role="menuitem">
                    <a href="#PEI">PEI</a>
                </li>
                <li role="menuitem">
                    <a href="#QC">QC</a>
                </li>
                <li role="menuitem">
                    <a href="#SK">SK</a>
                </li>
                <li role="menuitem">
                    <a href="#NT">NT</a>
                </li>
                <li role="menuitem">
                    <a href="#NU">NU</a>
                </li>
                <li role="menuitem">
                    <a href="#YT">YT</a>
                </li>
            </ul>
        </nav>
    );
}