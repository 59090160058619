export function Footer() {
    return (
        <footer className="App-footer">
        <div className="App-footer-l">
          <p>
            <a href="mailto:schuyh+cc@swalwellstudios.net?subject=Canucks%20and%20Crannies&body=Hi%20Sky,">Contact Us</a>
            &nbsp; | &nbsp;
            <a href="/">Another Link</a>
          </p>
        </div>
        <div className="App-footer-r">
          <p>
            Swalwell Studios © 2023
          </p>
        </div>
      </footer>
    );
}