import { Header } from './components/Header';
import { NavBar } from './components/NavBar';
import { About } from './components/About';
import { Explore } from './components/Explore';
import { Footer } from './components/Footer';
import './App.css';

function App() {
  return (
    <div>
      <Header />
      <NavBar />
      <main className="App">
        <About />
        <Explore />
      </main>
      <Footer />
    </div>
  );
}

export default App;