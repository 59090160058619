import logo from '../igloo.jpg';

export function Header() {
    const title = "Canucks & Crannies"
    const tagline = "Exploring Canada's lesser-known attractions"
    return (
        <header role="banner" className="App-header">
            <img src={logo} alt="Igloo" />
            <p className="Att">Image by <a href=" https://www.vectorportal.com" style={{ color: "#add8e6" }}>Vectorportal.com</a>, <a class="external text" href="https://creativecommons.org/licenses/by/4.0/" style={{ color: "#add8e6" }}>CC</a></p>
            <h1>{title}</h1>
            <p>{tagline}</p>
        </header>
    );
}